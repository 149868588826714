<template>
  <v-avatar rounded variant="tonal" class="pa-2">
    <organization-file-image
      v-if="organization?.icon"
      :organization-id="organization._id"
      :default-icon="organizationIcon"
      file-type="icon"
      width="32px"
      height="32px"
    />
    <v-icon v-else>{{ organizationIcon }}</v-icon>
  </v-avatar>
</template>

<script setup lang="ts">
const { organization } = defineProps<{
  organization: Organization
}>()

const organizationIcon = computed(() => {
  if (!organization) {
    return "help_center"
  }

  return `$${organization.type.toLowerCase()}`
})
</script>
